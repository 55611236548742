import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/layout/layout"
import Seo from "components/layout/seo"

import ImgBackground from "components/heroes/ImgBackground"
import Section50Background from "components/heroes/Section50Background"
import TitleBox from "components/titles/Titlebox"

import TeamServiceBlock from "../components/blocks/TeamService"
import ContactCTABlock from "../components/blocks/ContactCTA"



const UtilityScalePage = () => {

    // using static header image, load here
    const data = useStaticQuery(
        graphql`
      query {
        heroImage: file(relativePath: { eq: "backgrounds/floating-pv.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
            )
          }
        }
        pvEvImage: file(relativePath: { eq: "general/pv-ev-charging.jpg" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1200
              )
            }
          }
      }
    `
    )

    return (
        <Layout>
            <Seo title="Utility Scale Solar" />

            {/* Title banner */}
            <ImgBackground image={data.heroImage}>
                <div className="container m-auto py-10 lg:pb-0 md:pt-40 lg:pt-60 lg:flex">
                    <TitleBox title="Utility Scale Solar" caption="Products">
                        <p className="text-2xl text-white font-normal">Utility-scale solar PV is now a mainstream source of energy in many parts of the world. But developing PV plants is still a complex process, requiring in-depth knowledge of planning, permitting, procurement, construction and commissioning.</p>
                    </TitleBox>
                </div>
            </ImgBackground>


            {/* Content Area */}
            <section className="page--content py-16 lg:py-32 lg:pb-16">
                <div className="container lg:flex lg:space-x-12">

                    <div className="content-area mb-12 lg:w-1/2">
                        <p className="text-leader">Any mistakes you make in the development of the plant could affect its profitability forever, so it pays to rely on experienced engineering, procurement and construction providers (EPC) and subcontractors.</p>
                        <p>At Pacific Green, we not only have a track record in the development of major solar projects but also deep relationships with key PV technology vendors in China. This means we can source high-quality components rapidly and cost-effectively, delivering highly efficient projects on time and on budget.</p>
                        <p>If you are an asset developer or investor, then we can act as a full EPC on your behalf. If you are an EPC contractor, then we can work with you on technology sourcing and asset integration. </p>
                    </div>

                    <div className="image-quote lg:w-1/2">
                        <StaticImage src="../images/general/groundmount-non-penetration.jpg" width="600" height="300" alt="utility scale solar" className="rounded-xl rounded-tl-none" />
                        <div className="p-8 bg-primary text-white rounded-xl text-3xl font-light max-w-lg relative float-right -mt-8 z-2 mb-8">We can source high-quality components rapidly and cost-effectively, delivering highly efficient projects on time and on budget.</div>
                        <div className="clear-both"></div>
                    </div>

                </div>
            </section>

            {/* 50/50 image pullquote divider */}
            <section className="bg-primary-50">
                <div className="container lg:flex">
                    <div className="lg:w-1/2">
                        <div className="p-8 md:p-16 lg:p-32 md:pl-0 lg:pl-0 text-2xl lg:text-2xl xl:text-3xl font-light max-w-4xl justify-items-end">We are experts at integrating solar with battery storage and electric vehicle charging systems, so we can help you enhance the value of your solar project by giving you access to additional revenue streams beyond simple electricity sales to the grid.</div>
                    </div>
                    <div className="lg:w-1/2">
                        <div className="lg:float-left lg:w-1/2-screen h-80 lg:h-full">
                            <Section50Background image={data.pvEvImage}>
                                <div className="bg-white bg-opacity-10 w-full h-full block">

                                </div>
                            </Section50Background></div>
                    </div>
                </div>
            </section>

            {/* team service block */}
            <section className="container lg:flex lg:space-x-32 py-16 lg:py-32">
                <TeamServiceBlock/>                
            </section>

            {/* CTA contact block */}
            <section className="py-16 lg:py-32">
                <ContactCTABlock />
            </section>

        </Layout>
    )
}

export default UtilityScalePage
